

















import { Component, Prop, Vue } from 'vue-property-decorator'
import { Tooltip as BTooltip } from 'bootstrap'

@Component
export default class Tooltip extends Vue {
  @Prop({default: ''})
  title !: string;

  mounted() { 
    new BTooltip(this.$refs.button as Element)
  }
}
