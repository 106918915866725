






















import { ProgressBarStep } from '@/models/ProgressBarStep'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ProgressBar extends Vue {
  @Prop({ default: () => ([]) })
  steps!: ProgressBarStep[]

  @Prop({ default: '0' })
  value!: string;

  getIcon (icon: string, step: number): string[] {
    const classes: string[] = []

    if (step.toString() === this.value) {
      classes.push('fas')
    } else {
      classes.push('fal')
    }
    classes.push(icon)
    return classes
  }
}
