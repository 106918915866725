






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class FTab extends Vue {
  @Prop()
  title!: string;

  active = false;
}
